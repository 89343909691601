<template>
    <!-- Undefined cart means no logged in viewer means empty cart -->
    <div class="flex h-full flex-col gap-6">
        <div
            class="hidden justify-center text-2xl font-semibold uppercase tracking-wider md:flex"
        >
            ({{ cart?.items.length ?? 0 }}) Cart &nbsp;<span
                class="text-gray-500"
                >&mdash;</span
            >&nbsp; {{ activeFacility.shortName }}
        </div>

        <div v-if="otherFacilityCarts.length > 0">
            <div
                v-for="(otherFacilityCart, index) in otherFacilityCarts"
                :key="index"
                class="text-orange-500 flex justify-center"
            >
                <div v-if="otherFacilityCart.count === 1">
                    Heads up! You have 1 item in your cart at
                    {{ otherFacilityCart.facility.shortName }}
                </div>
                <div v-else>
                    Heads up! You have {{ otherFacilityCart.count }} items in
                    your cart at
                    {{ otherFacilityCart.facility.shortName }}
                </div>
            </div>
        </div>

        <template v-if="cart === undefined">
            <div class="flex justify-center">Your cart is currently empty.</div>
        </template>
        <template v-else-if="cart.items.length === 0">
            <div class="flex justify-center">Your cart is currently empty.</div>
        </template>
        <template v-else>
            <div class="flex-1 space-y-2 overflow-auto md:space-y-4">
                <div
                    v-for="(item, index) in cart.items"
                    :key="item.key"
                >
                    <contract-cart-item
                        v-if="item.__typename === 'CartContractItem'"
                        :item="item"
                    />

                    <prime-message
                        v-else
                        severity="error"
                        :closable="false"
                    >
                        Whoops! You have an item in your cart we do not know how
                        to display.
                    </prime-message>

                    <div
                        v-if="index !== cart.items.length - 1"
                        class="mt-2 border md:mt-3"
                    />
                </div>
            </div>

            <div class="flex flex-col justify-center">
                <div>
                    <div class="flex items-center justify-between text-lg">
                        <span class="text-900 font-medium">Subtotal</span>
                        <span class="text-900">{{ subtotal }}</span>
                    </div>

                    <div
                        v-if="inclusiveTaxTotal.isNotZero()"
                        class="flex items-center justify-between md:mt-6"
                    >
                        <span class="text-800">Inclusive Tax</span>
                        <span class="text-800">{{
                            inclusiveTaxTotal.formatCurrency()
                        }}</span>
                    </div>

                    <div class="flex items-center justify-between">
                        <span class="text-800">Tax</span>
                        <span class="text-800">{{ exclusiveTaxTotal }}</span>
                    </div>

                    <div v-if="showTaxDetailsToggle && !taxDetailsShown">
                        <prime-button
                            link
                            class="!p-0"
                            size="small"
                            @click="taxDetailsShown = true"
                        >
                            Show Tax Details...
                        </prime-button>
                    </div>

                    <template v-if="taxDetailsShown">
                        <div
                            v-for="taxLineItem in cart.salesTaxItems"
                            :key="taxLineItem.key"
                            class="flex items-center justify-between text-sm"
                        >
                            <span class="text-700">{{
                                taxLineItem.description
                            }}</span>
                            <span class="text-700">{{
                                formatCurrency(taxLineItem.total)
                            }}</span>
                        </div>
                        <div>
                            <prime-button
                                link
                                class="!p-0"
                                size="small"
                                @click="taxDetailsShown = false"
                            >
                                Hide Tax Details
                            </prime-button>
                        </div>
                    </template>

                    <div class="mt-2 flex items-center justify-between md:mt-6">
                        <span class="text-900 text-xl font-bold">Total</span>
                        <span class="text-900 text-2xl font-medium">{{
                            total
                        }}</span>
                    </div>
                </div>

                <prime-button
                    id="cartSidebarCheckoutButton"
                    label="Checkout"
                    class="mt-4"
                    @click="handleCheckout"
                />

                <prime-button
                    label="Continue Shopping"
                    outlined
                    class="mt-2"
                    :disabled="isPending"
                    @click="$emit('close')"
                />
            </div>
        </template>
    </div>

    <confirm-dialog
        group="deleteCartItem"
        :pt="{
            root: {
                class: '!w-[350px]'
            },
            header: {
                class: ''
            },
            footer: {
                class: 'space-x-4'
            },
            rejectButton: {
                outlined: true
            }
        }"
        :pt-options="{
            mergeProps: true
        }"
    >
        <template #icon>
            <span class="pr-2 text-xl">
                <i class="pi pi-exclamation-triangle" />
            </span>
        </template>
    </confirm-dialog>

    <prime-dialog
        v-model:visible="showErrorDialog"
        modal
        :pt="{ root: { class: '!w-[350px]' } }"
        header="Error"
    >
        {{ errorMessage }}
    </prime-dialog>
</template>

<script lang="ts">
import { graphql } from '~/resources/graphql'

export const CartContentsCartFragment = graphql(/* GraphQL */ `
    fragment CartContentsCartFragment on Cart {
        items {
            __typename
            ... on CartContractItem {
                key
                ...CartSidebarContractCartItemFragment
                contract {
                    ... on PlanContract {
                        readyForPurchase(input: { checkDocument: true })
                    }
                }
            }
            ... on CartProductItem {
                key
            }
        }
        inclusiveTaxTotal
        exclusiveTaxTotal
        subtotal
        salesTaxItems {
            key
            description
            total
        }
        total
    }
`)
</script>

<script setup lang="ts">
import ConfirmDialog from 'primevue/confirmdialog'
import { useActiveFacility, useCartData, useRpQuery } from '#imports'
import PrimeButton from 'primevue/button'
import { type ComputedRef, onServerPrefetch } from 'vue'
import { computed, ref } from 'vue'
import { Decimal } from '~/composables/support/decimal'
import { getFragmentData } from '~/resources/graphql'
import ContractCartItem from '~/components/CartSidebar/ContractCartItem.vue'
import { useCreateCheckout } from '~/components/Checkout/create-checkout-mutation'
import { useRouter } from 'vue-router'
import PrimeMessage from 'primevue/message'
import { useQuery } from '@tanstack/vue-query'
import type { CartSidebarViewerCartFragmentFragment } from '~/resources/graphql/graphql'
import PrimeDialog from 'primevue/dialog'
import { formatCurrency } from '~/composables/support/localization'
import { useRouteLink } from '~/composables/routing'

const emit = defineEmits<{
    (e: 'close'): void
    (e: 'checkout'): void
}>()

const cartData = useCartData()
const cart: ComputedRef<CartSidebarViewerCartFragmentFragment | undefined> =
    computed(() => {
        const data = cartData.value
        // Data still loading
        if (data === undefined) {
            return undefined
        }

        return getFragmentData(CartContentsCartFragment, data)
    })

const subtotal = computed(() =>
    new Decimal(cart.value?.subtotal ?? '0').formatCurrency()
)
const inclusiveTaxTotal = computed(
    () => new Decimal(cart.value?.inclusiveTaxTotal ?? '0')
)
const exclusiveTaxTotal = computed(() =>
    new Decimal(cart.value?.exclusiveTaxTotal ?? '0').formatCurrency()
)
const total = computed(() =>
    new Decimal(cart.value?.total ?? '0').formatCurrency()
)
const showTaxDetailsToggle = computed(() => {
    if (cart.value?.salesTaxItems.length === undefined) {
        return false
    }
    return cart.value?.salesTaxItems.length > 1
})

const query = useRpQuery()
const activeFacility = useActiveFacility()
const { data, suspense } = useQuery({
    queryKey: ['OtherFacilityCartsQuery', activeFacility],
    queryFn: () => {
        return query(
            graphql(/* GraphQL */ `
                query OtherFacilityCartsQuery($facilityId: ID!) {
                    viewer {
                        cartCounts(facilityId: $facilityId) {
                            facility {
                                shortName
                            }
                            count
                        }
                    }
                }
            `),
            {
                facilityId: activeFacility.value.id
            }
        )
    }
})

const otherFacilityCarts = computed(() => {
    if (data.value === undefined || data.value.viewer === null) {
        return []
    } else {
        return data.value.viewer.cartCounts
    }
})

const router = useRouter()
const { facilityCheckoutLink } = useRouteLink()
const showErrorDialog = ref(false)
const errorMessage = ref('')
const { mutate, isPending } = useCreateCheckout()
async function handleCheckout() {
    mutate(activeFacility.value.id, {
        onSuccess: data => {
            if (data.createCheckout.__typename === 'CreateCheckoutResult') {
                router
                    .push(
                        facilityCheckoutLink(
                            activeFacility.value.slug,
                            data.createCheckout.recordId
                        )
                    )
                    .then(() => {
                        emit('checkout')
                    })
                return
            } else if (
                data.createCheckout.__typename === 'CreateCheckoutIncomplete'
            ) {
                showErrorDialog.value = true
                errorMessage.value =
                    'You have ' +
                    data.createCheckout.count +
                    ' incomplete contract(s)! Please edit or remove that contract(s) and try again.'
                console.log(data.createCheckout.results)
                return
            }

            throw new Error()
        }
    })
}

const taxDetailsShown = ref(false)

onServerPrefetch(async () => {
    await suspense()
})
</script>
